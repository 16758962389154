<!--
 * @Author: wang peng
 * @Description: 实时订单列表  
-->
<template>
  <scroll-layout class="directConnectionPile-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div style="width: 100%">
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'order:charge:realtime:column'" type="plain" @click="customColumns">
              自定义列
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
      <!--自定义列弹窗 -->
      <ykc-operation-dialog
        title="自定义列"
        show-footer
        :show.sync="showDialog"
        :before-close="onCLose"
        :before-cancel="onCLose"
        :before-confirm="beforeConfirm">
        <div slot="content">
          <el-form
            size="mini"
            label-width="80px"
            ref="customForm"
            class="customForm"
            :model="customForm"
            :rules="customRules">
            <el-form-item label="列选项:" prop="checkOption">
              <ykc-checkbox
                :configSet="{
                  label: 'label',
                  value: 'value',
                }"
                :data="customData"
                v-model="customForm.checkOption"
                @change="checkboxChange"></ykc-checkbox>
            </el-form-item>
          </el-form>
        </div>
      </ykc-operation-dialog>
    </div>
  </scroll-layout>
</template>
<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { orderPage, barrierPage, customerOrg, propertySettleManage } from '@/service/apis';
  import { localSetItem, localGetItem, sessionGetItem, code } from '@/utils';

  export default {
    name: 'realTimeList',
    components: {},
    inject: ['reload'],
    data() {
      return {
        timer: null, // 定时器
        showDialog: false,
        propertyData: [],
        isProperty: false,
        searchParams: {
          startChargingTimeStart: '',
          startChargingTimeEnd: '',
          userAccount: '',
          plateNumber: '',
          stationId: '',
          userCompanyId: '', // 用户机构id
          tradeSeq: '',
          cardNo: '',
          tradeStatus: '',
          zdlTradeSeq: '',
          orderAbnormalType: '',
          chargingSource: '',
          chargingType: '',
          propertyId: '',
          isTestStation: '',
        },
        userCompanyIdData: [], // 归属机构数据
        stationIdData: [],
        customForm: {
          checkOption: [0, 1, 2, 3, 4, 5, 6, 7, 8, 19],
        },
        customData: [
          {
            value: 0,
            label: '充电单号',
          },
          {
            value: 19,
            label: '终端编码',
          },
          {
            value: 1,
            label: '充电用户',
          },
          {
            value: 2,
            label: '归属机构',
          },
          {
            value: 3,
            label: '车牌号码',
          },
          {
            value: 4,
            label: '开始时间',
          },
          {
            value: 5,
            label: '充电站',
          },
          {
            value: 6,
            label: '起始SOC',
          },
          {
            value: 7,
            label: '电量',
          },
          {
            value: 8,
            label: '商户',
          },
          {
            value: 9,
            label: '充电卡号',
          },
          {
            value: 10,
            label: '电桩品牌',
          },
          {
            value: 11,
            label: '电桩型号',
          },
          {
            value: 12,
            label: '电桩功率',
          },
          {
            value: 13,
            label: '电桩类型',
          },
          {
            value: 14,
            label: '启动来源',
          },
          {
            value: 15,
            label: '车辆VIN',
          },
          {
            value: 16,
            label: '流量互联单号',
          },
          {
            value: 17,
            label: '桩联互联单号',
          },
          {
            value: 18,
            label: '充电类型',
          },
        ],
        customRules: {
          checkOption: [{ required: true, message: '请至少选择一个列名称', trigger: 'blur' }],
        },
        tableTitle: '订单列表',
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '充电单号', prop: 'tradeSeq', width: '200px', minWidth: '150px', id: 0 },
          { label: '终端编码', prop: 'gunCode', minWidth: '160px', id: 19 },
          {
            label: '充电用户',
            prop: 'nickName',
            width: '200px',
            scopedSlots: {
              default: ({ row }) => {
                return (
                  <span>
                    {row.userAccount}({row.nickName})
                  </span>
                );
              },
            },
            id: 1,
          },
          { label: '归属机构', prop: 'companyName', minWidth: '150px', id: 2 },
          { label: '车牌号码', prop: 'plateNumber', minWidth: '150px', id: 3 },
          { label: '开始时间', prop: 'startTime', width: '200px', minWidth: '150px', id: 4 },
          { label: '充电站', prop: 'stationName', minWidth: '150px', id: 5 },
          { label: '起始SOC(%)', prop: 'startSoc', minWidth: '150px', id: 6 },
          { label: '电量(度)', prop: 'chargedPowerOri', minWidth: '150px', id: 7 },

          { label: '商户', prop: 'stationOperatorName', width: '200px', minWidth: '150px', id: 8 },
          { label: '充电卡号', prop: 'cardNo', minWidth: '150px', id: 9 },
          { label: '电桩品牌', prop: 'name', minWidth: '150px', id: 10 },
          { label: '电桩型号', prop: 'modelName', minWidth: '150px', id: 11 },
          { label: '电桩功率(KW)', prop: 'pilePower', minWidth: '150px', id: 12 },
          {
            label: '电桩类型',
            prop: 'pileType',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                return <span>{row.pileType === 1 ? '直流' : '交流'}</span>;
              },
            },
            id: 13,
          },
          {
            label: '启动来源',
            prop: 'chargingSource',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                const cls = [
                  { id: 1, name: '扫码启动' },
                  { id: 2, name: '扫码启动' },
                  { id: 3, name: '刷卡充电' },
                  { id: 4, name: '即插即充' },
                  { id: 5, name: '流量方' },
                  { id: 6, name: '预约充电' },
                  { id: 7, name: '离线卡' },
                ][Number(row.chargingSource) - 1];

                if (!cls) {
                  return '一';
                }

                return <span>{cls.name}</span>;
              },
            },
            id: 14,
          },
          { label: '车辆VIN', prop: 'carVinCode', width: '200px', minWidth: '150px', id: 15 },
          { label: '流量互联单号', prop: 'zdlTradeSeq', minWidth: '150px', id: 16 },
          { label: '桩联互联单号', prop: 'pileTradeSeq', minWidth: '150px', id: 17 },
          {
            label: '充电类型',
            prop: 'chargingType',
            scopedSlots: {
              default: ({ row }) => {
                const typeMap = {
                  1: '有序充电',
                  0: '正常充电',
                };
                const type = typeMap[row.chargingType] || '——';
                return (
                  <div>
                    <span title={type}>{type}</span>
                  </div>
                );
              },
            },
            minWidth: '150px',
            id: 18,
          },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
      };
    },
    created() {
      this.isProperty = JSON.parse(sessionGetItem('propertyFlag'));
      if (this.isProperty) {
        this.searchParams.propertyId = JSON.parse(sessionGetItem('propertyId'));
      }
      const customForm = localGetItem('realTimeOrderCustomForm');

      if (customForm) {
        this.customForm.checkOption = customForm;
        this.tableColumns = this.tableColumns.filter(item => {
          return customForm.includes(item.id);
        });
      } else {
        this.tableColumns = this.tableColumns.filter(item => {
          return ![9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(item.id);
        });
      }
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcDatePicker',
            key: 'operationTime',
            label: '开始充电 ',
            placeholder: '请选择充电时间',
          },
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '用户账号',
            placeholder: '请输入用户账号',
          },
          {
            comName: 'YkcInput',
            key: 'plateNumber',
            label: '车牌号码',
            placeholder: '请输入车牌号码',
          },
          {
            comName: 'YkcInput',
            key: 'tradeSeq',
            label: '充电单号',
            placeholder: '请输入充电单号',
          },
          {
            comName: 'YkcInput',
            key: 'cardNo',
            label: '充电卡号',
            placeholder: '请输入充电卡号',
          },
          {
            remote: true,
            comName: 'YkcDropdown',
            key: 'userCompanyId',
            label: '归属机构',
            placeholder: '请输入归属机构',
            data: this.userCompanyIdData,
          },

          {
            remote: true,
            comName: 'YkcDropdown',
            key: 'stationId',
            label: '电站名称',
            placeholder: '请输入电站名称',
            data: this.stationIdData,
          },
          {
            clearable: true,
            comName: 'YkcDropdown',
            key: 'chargingSource',
            label: '启动来源',
            placeholder: '请选择启动来源',
            data: [
              { id: '1', name: '扫码启动' },
              { id: '3', name: '刷卡充电' },
              { id: '4', name: '即插即充' },
              { id: '5', name: '流量方' },
              { id: '6', name: '预约充电' },
              { id: '7', name: '离线卡' },
            ],
          },
          {
            comName: 'YkcDropdown',
            key: 'chargingType',
            label: '充电类型',
            placeholder: '请输入充电类型',
            data: [
              { id: 0, name: '正常充电' },
              { id: 1, name: '有序充电' },
            ],
          },
          {
            comName: 'YkcInput',
            key: 'gunCode',
            label: '终端编码',
            placeholder: '请输入终端编码',
          },
          {
            remote: true,
            comName: 'YkcDropdown',
            key: 'propertyId',
            label: '所属物业',
            placeholder: '请选择所属物业',

            data: this.propertyData,
          },
        ];
      },
    },
    mounted() {
      this.requestCompanyData();
      this.requestStationData();
      this.requestPropertyList();
      this.requestList();
      this.initData();
      this.timer = setInterval(() => {
        setTimeout(this.requestList(), this.initData(), 0);
      }, 1000 * 60);
    },
    methods: {
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            id: '2',
            enabled: () => code('order:charge:realtime:detail'),
            text: '详情',
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/orderManagement/realTimeOrder/Detail',
                query: { recordId: row.recordId, tradeNo: row.tradeSeq, startTime: row.startTime },
              });
            },
          },
          {
            id: '3',
            // enabled: () => true,
            enabled: () => code('order:charge:realtime:close'),
            text: '解单',
            handleClick: (btn, { row }) => {
              YkcDialog({
                showTitle: false,
                showFooter: true,
                dialogType: 'feedback',
                desc: '是否确定解单？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.closeOrder(row);
                },
              });
            },
          },
        ];
      },
      /**
       * 自定义列
       * */
      customColumns() {
        this.showDialog = true;
      },

      /**
       * 组件change方法调用信息提示
       */
      checkboxChange(e) {
        this.customForm.checkOption = e;
        console.log('this.customForm+++', this.customForm);
      },
      /**
       *  弹窗确认
       * */
      beforeConfirm(done) {
        console.log('确认', this.customForm);
        this.$refs.customForm.validate(valid => {
          if (valid) {
            localSetItem('realTimeOrderCustomForm', JSON.stringify(this.customForm.checkOption));
            done();
            this.reload();
          }
        });
      },
      /**
       * 弹窗关闭
       * */
      onCLose() {
        this.showDialog = false;
      },
      /**
       * 获取归属电站数据
       */
      requestStationData() {
        barrierPage
          .findStationList({})
          .then(res => {
            console.log('获取归属电站数据+++++', res);
            this.stationIdData = res.map(item => ({
              id: item.stationId,
              name: item.stationName,
            }));
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取归属机构数据
       */
      requestCompanyData() {
        customerOrg
          .queryOrg({})
          .then(res => {
            console.log('获取归属机构数据+++++', res);
            this.userCompanyIdData = res || [];
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取物业列表
       */
      requestPropertyList() {
        propertySettleManage
          .propertyList({
            current: 1,
            size: 99999,
          })
          .then(res => {
            console.log('res', res);
            this.propertyData = (res || []).map(item => {
              return {
                id: item.id,
                name: item.propertyName,
              };
            });
            if (this.isProperty) {
              this.searchParams.propertyId = res[0].id;
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 解单
       */
      closeOrder(item) {
        orderPage.closeOrder({ recordId: item.recordId }).then(res => {
          console.log(res);
          if (res) {
            this.$message.success('解单成功');
          } else {
            this.$message.error('解单失败');
          }

          this.requestList();
        });
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        console.log('searchData++++', searchData);
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, searchData);

        if (searchData.operationTime && searchData.operationTime.length > 0) {
          [this.searchParams.startChargingTimeStart, this.searchParams.startChargingTimeEnd] =
            searchData.operationTime;
        }

        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        console.log('清空查询', form);
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        orderPage
          .chargingOrderPage(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
    },
    beforeDestroy() {
      clearInterval(this.timer);
      this.timer = null;
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-checkbox .icon-check {
    top: 7px;
  }

  .directConnectionPile-list {
    .ykc-table {
      .el-table .el-table__row {
        .cell .userStatus {
          padding-left: 8px;
          position: relative;
        }

        .cell .userStatus::after {
          display: block;
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background: #d0021b;
          position: absolute;
          top: 5.5px;
          left: 0;
        }

        .cell .status-blue::after {
          background: #4d8cfd;
        }

        .cell .status-green::after {
          background: #02c498;
        }

        .cell .status-black::after {
          background: #9b9b9b;
        }
      }

      .sealBtn {
        color: #4d8cfd;
        cursor: pointer;
      }
    }
  }

  ::v-deep.ykc-checkbox .icon-check {
    top: 8px;
  }

  ::v-deep.customForm .el-form-item,
  ::v-deep.customForm .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }
</style>
